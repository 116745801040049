<template>
  <div class="relative">
    <div class="flex ml-3 sm:ml-4 bell">
      <div class="-mr-2 sm:-mr-3 relative" v-if="new_app_version">
        <span class="flex h-2 w-2 sm:h-3 sm:w-3">
          <span
            class="
              animate-ping
              absolute
              inline-flex
              h-2
              w-2
              sm:h-3 sm:w-3
              rounded-full
              bg-red-600
              opacity-75
            "
          ></span>
          <span
            class="
              relative
              inline-flex
              rounded-full
              h-2
              w-2
              sm:h-3 sm:w-3
              bg-red-600
            "
          ></span>
        </span>
      </div>
      <bell
        :width="'w-6 sm:w-8'"
        :height="'h-6 sm:h-8'"
        :color="'text-gray-700'"
      ></bell>
    </div>
  </div>
</template>

<script>
import bell from '../Icons/bell.vue'
export default {
  components: { bell },
  computed: {
    new_app_version() {
      return this.$store.state.new_app_version
    }
  },
  methods: {
    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },
  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style scoped>
.bell:hover + .update-card {
  display: block;
}

.update-card {
  display: none;
  position: absolute;
  top: 43px;
  right: -7px;
  background: white;
  @apply text-primary;
  @apply w-80;
  @apply py-2;
  @apply px-2;
  @apply shadow-lg;
}
</style>
