<template>
  <aside
    class="
      bg-white
      text-black text-xl text-left
      border-r
      h-screen
      border-gray-50
      shadow-sm
      overflow-y-auto
      scrollbar
    "
  >
    <div class="draft">For Presentation Purpose</div>
    <div
      class="
        pl-10
        pb-2
        border-b border-gray-50
        shadow-sm
        w-full
        flex
        items-center
        justify-between
        cursor-pointer
      "
    >
      <div @click="homePage">
        <img src="/img/Logo_colour.png" alt="brand logo" class="w-32" />
      </div>
      <span
        class="mr-5 inline-block xl:hidden"
        @click="$emit('close-side-bar')"
      >
        <close-icon></close-icon>
      </span>
    </div>
    <div class="w-full py-5 mb-12">
      <a
        class="
          py-2
          px-2
          bg-primary
          shadow-xl
          w-4/5
          mx-auto
          rounded-lg
          flex
          justify-between
          items-center
        "
        href="javascript:;"
        @click="$emit('create-new-card')"
      >
        <span class="text-white items-center pl-8">Create Client</span>
        <add-icon :color="'text-white'"></add-icon>
      </a>
    </div>
    <nav-link :on_active="on_active"></nav-link>
    <other-nav-link :on_active="on_active"></other-nav-link>
  </aside>
</template>

<script>
import addIcon from '../Icons/addIcon.vue'
import CloseIcon from '../Icons/closeIcon.vue'
import NavLink from './NavLink.vue'
import OtherNavLink from './OtherNavLink.vue'
export default {
  components: { addIcon, NavLink, OtherNavLink, CloseIcon },
  props: {
    on_active: String
  },
  data() {
    return {}
  },
  methods: {
    homePage() {
      this.$router.push({
        name: 'Home'
      })
    }
  }
}
</script>

<style scoped>
.draft {
  text-align: center;
  color: #fff;
  top: 74px;
  left: -61px;
  width: 315px;
  padding: 3px;
  position: relative;
  transform: rotate(-45deg);
  background-color: #94a5a6d9;
  border-color: #94a5a6d9;
}
</style>
