<template>
  <section class="mt-60 border-t border-gray-50 shadow-sm">
    <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <a
          class="
            pl-9
            py-3
            w-full
            border-l-4 border-white
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          href="javascript:;"
          @click="signOut()"
          >Log Out</a
        >
      </div>
    </div>
    <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4 border-white
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          to="/"
          >Settings</router-link
        >
      </div>
    </div>
    <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4 border-white
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          to="/"
          >FAQs</router-link
        >
      </div>
    </div>
    <!-- <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4 border-white
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          to="/"
          >Report</router-link
        >
      </div>
    </div> -->
  </section>
</template>

<script>
export default {
  methods: {
    signOut() {
      this.$store.dispatch('signOut')
    }
  },
}
</script>

<style></style>
