<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-20
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 md:w-2/3 w-full mx-auto z-30">
          <section class="px-4 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div
              @click="$emit('close-card-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>
            <section class="space-y-1 sm:mt-10 mt-10">
              <div
                class="
                  w-full
                  flex flex-col
                  sm:flex-row
                  align-middle
                  items-center
                "
              >
                <h2 class="leading-relaxed text-xl text-left sm:w-1/3">
                  Create new client
                </h2>
              </div>
            </section>
            <section class="sm:w-5/6 sm:mx-auto">
              <section class="pt-10 md:pt-20 pb-10 px-0 xl:px-14 space-y-6">
                <h1 class="text-lg mb-5">Client details</h1>
                <div class="grid grid-cols-1 gap-y-8">
                  <div class="">
                    <input-text
                      placeholder="Full Name"
                      required
                      v-model="client_details.name"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      placeholder="Business Name"
                      required
                      v-model="client_details.bus_name"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      type="email"
                      placeholder="Email"
                      required
                      v-model="client_details.email"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      type="number"
                      placeholder="Phone number"
                      required
                      v-model="client_details.phone"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      type="text"
                      placeholder="Address"
                      required
                      v-model="client_details.address"
                    ></input-text>
                  </div>
                </div>
                <section class="w-full flex justify-center mt-6">
                  <button
                    class="block py-3 px-16 text-white bg-primary rounded-md"
                    @click="submit"
                  >
                    Save
                  </button>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <loading-model v-if="loading"></loading-model>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import uniqid from 'uniqid'
import InputText from '../Utilities/InputText.vue'
import CloseIcon from '../Icons/closeIcon.vue'
import LoadingModel from '../Utilities/loadingModel.vue'
export default {
  components: { InputText, CloseIcon, LoadingModel },

  props: {},

  data() {
    return {
      loading: false,
      client_details: {
        name: '',
        bus_name: '',
        email: '',
        phone: '',
        address: ''
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    }
  },

  watch: {},

  methods: {
    submit() {
      this.loading = true
      var uniqueId = uniqid()
      var client = {
        isActive: false,
        id: uniqueId,
        client_details: this.client_details,
        hospital_id: this.user.hospital_id,
        createdAt: Date.now(),
        updatedAt: Date.now()
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
          this.$store.dispatch('card/createClient', client)
          this.$emit('close-card-modal')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  },

  mounted() {}
}
</script>

<style scoped>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
