var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"mr-10 cursor-pointer"},[_c('div',{},[_c('router-link',{staticClass:"\n          pl-9\n          py-3\n          w-full\n          border-l-4\n          hover:text-primary hover:border-primary\n          block\n          hover:bg-gray-200\n          rounded-r-full\n        ",class:{
          'text-primary border-primary bg-gray-200': _vm.on_active == 'dashboard',
          'border-white text-black bg-white': !_vm.on_active != 'dashboard'
        },attrs:{"to":"/dashboard"}},[_vm._v(" Dashboard")])],1)]),(_vm.user.role >= 3)?_c('div',{staticClass:"my-3 cursor-pointer mr-10"},[_c('div',{},[_c('router-link',{staticClass:"\n          pl-9\n          py-3\n          w-full\n          border-l-4\n          hover:text-primary hover:border-primary\n          block\n          hover:bg-gray-200\n          rounded-r-full\n        ",class:{
          'text-primary border-primary bg-gray-200': _vm.on_active == 'pets',
          'border-white text-black bg-white': !_vm.on_active != 'pets'
        },attrs:{"to":"/pets"}},[_vm._v("Pets")])],1)]):_vm._e(),_c('div',{staticClass:"my-3 mr-10 cursor-pointer"},[_c('div',{},[_c('router-link',{staticClass:"\n          pl-9\n          py-3\n          w-full\n          border-l-4\n          hover:text-primary hover:border-primary\n          block\n          hover:bg-gray-200\n          rounded-r-full\n        ",class:{
          'text-primary border-primary bg-gray-200': _vm.on_active == 'reports',
          'border-white text-black bg-white': !_vm.on_active != 'reports'
        },attrs:{"to":"/reports"}},[_vm._v("Reports")])],1)]),(_vm.user.role >= 3)?_c('div',{staticClass:"my-3 mr-10 cursor-pointer"},[_c('div',{},[_c('router-link',{staticClass:"\n          pl-9\n          py-3\n          w-full\n          border-l-4\n          hover:text-primary hover:border-primary\n          block\n          hover:bg-gray-200\n          rounded-r-full\n        ",class:{
          'text-primary border-primary bg-gray-200': _vm.on_active == 'clients',
          'border-white text-black bg-white': !_vm.on_active != 'clients'
        },attrs:{"to":"/clients"}},[_vm._v("Clients")])],1)]):_vm._e(),(_vm.user.role >= 3)?_c('div',{staticClass:"my-3 mr-10 cursor-pointer"},[_c('div',{},[_c('router-link',{staticClass:"\n          pl-9\n          py-3\n          w-full\n          border-l-4\n          hover:text-primary hover:border-primary\n          block\n          hover:bg-gray-200\n          rounded-r-full\n        ",class:{
          'text-primary border-primary bg-gray-200': _vm.on_active == 'staff',
          'border-white text-black bg-white': !_vm.on_active != 'staff'
        },attrs:{"to":"/staff"}},[_vm._v("Staff")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }