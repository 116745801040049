<template>
  <div>
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current"
      :class="[width, height, color]"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-8'
    },
    height: {
      type: String,
      default: 'h-8'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
