<template>
  <input
    class="
      border-b border-nadis-ash2
      w-full
      px-2
      py-3
      font-medium
      bg-nadis-bg2
      focus:outline-none focus:border-primary
    "
    :value="value"
    @input="$emit('input', $event.target.value)"
    ref="input"
  />
</template>

<script>
export default {
  props: ['value'],

  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
