<template>
  <div class="fixed left-0 top-0 w-full bg-black bg-opacity-60 h-screen z-50">
    <div class="absolute top-2/4 w-full">
      <loading :width="'w-10'" :height="'h-10'"></loading>
    </div>
  </div>
</template>

<script>
import Loading from '../Icons/Loading.vue'
export default {
  components: { Loading }
}
</script>

<style></style>
