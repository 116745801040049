<template>
  <section>
    <div class="mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'dashboard',
            'border-white text-black bg-white': !on_active != 'dashboard'
          }"
          to="/dashboard"
        >
          Dashboard</router-link
        >
      </div>
    </div>
    <div class="my-3 cursor-pointer mr-10" v-if="user.role >= 3">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'pets',
            'border-white text-black bg-white': !on_active != 'pets'
          }"
          to="/pets"
          >Pets</router-link
        >
      </div>
    </div>
    <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'reports',
            'border-white text-black bg-white': !on_active != 'reports'
          }"
          to="/reports"
          >Reports</router-link
        >
      </div>
    </div>
    <div class="my-3 mr-10 cursor-pointer" v-if="user.role >= 3">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'clients',
            'border-white text-black bg-white': !on_active != 'clients'
          }"
          to="/clients"
          >Clients</router-link
        >
      </div>
    </div>
    <div class="my-3 mr-10 cursor-pointer" v-if="user.role >= 3">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'staff',
            'border-white text-black bg-white': !on_active != 'staff'
          }"
          to="/staff"
          >Staff</router-link
        >
      </div>
    </div>
    <!-- <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'billing',
            'border-white text-black bg-white': !on_active != 'billing'
          }"
          to="/billing"
          >Billing</router-link
        >
      </div>
    </div> -->
    <!-- <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'appointments',
            'border-white text-black bg-white': !on_active != 'appointments'
          }"
          to="/appointments"
          >Appointments</router-link
        >
      </div>
    </div> -->
    <!-- <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'trail',
            'border-white text-black bg-white': !on_active != 'trail'
          }"
          to="/trail"
          >Trail</router-link
        >
      </div>
    </div> -->
    <!-- <div class="my-3 mr-10 cursor-pointer">
      <div class="">
        <router-link
          class="
            pl-9
            py-3
            w-full
            border-l-4
            hover:text-primary hover:border-primary
            block
            hover:bg-gray-200
            rounded-r-full
          "
          :class="{
            'text-primary border-primary bg-gray-200': on_active == 'stock',
            'border-white text-black bg-white': !on_active != 'stock'
          }"
          to="/stock"
          >Stock</router-link
        >
      </div>
    </div> -->
  </section>
</template>

<script>
export default {
  props: {
    on_active: String
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style></style>
