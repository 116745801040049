<template>
  <header
    class="
      xl:py-5
      pt-8
      pb-5
      border-b border-gray-50
      shadow-sm
      w-full
      flex
      justify-between
      items-center
      align-middle
      xl:pr-20
      pr-7
    "
  >
    <section class="flex px-3 items-center">
      <div class="rounded shadow py-2 px-3 bg-white hidden">
        <a href="javascript:;">
          <menu-icon :width="'w-6'" :height="'h-6'"></menu-icon>
        </a>
      </div>
    </section>
    <section class="flex items-center justify-between">
      <div class="text-base sm:text-lg font-medium text-primary capitalize">
        {{ user.name }}
      </div>
      <div
        class="
          text-base
          sm:text-lg
          font-medium
          text-gray-700
          capitalize
          hidden
          md:block
        "
      >
        <span class="mx-2">|</span>
        {{ hospital.name }}
      </div>
      <div class="ml-5">
        <user-icon :width="'w-6 sm:w-10'" :height="'h-6 sm:h-10'"></user-icon>
      </div>
      <div>
        <notification-bell></notification-bell>
      </div>
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import menuIcon from '../Icons/menuIcon.vue'
import UserIcon from '../Icons/userIcon.vue'
import NotificationBell from '../Utilities/NotificationBell.vue'

export default {
  components: { menuIcon, NotificationBell, UserIcon },
  computed: {
    user() {
      return this.$store.state.user
    },

    ...mapState('hospital_list', ['hospital'])
  },

  mounted() {
    this.$store.dispatch(
      'hospital_list/getSingleHospital',
      this.user.hospital_id
    )
  }
}
</script>

<style></style>
