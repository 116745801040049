<template>
  <section
    class="
      w-full
      min-h-screen
      bg-dashboard
      grid
      xl:grid xl:grid-cols-16
      gap-x-1
    "
  >
    <div class="inline-block xl:hidden">
      <div class="absolute top-4 left-5 rounded shadow py-2 px-3 bg-white z-50">
        <a href="javascript:;" @click="show_menu = !show_menu">
          <menu-icon :width="'w-6'" :height="'h-6'"></menu-icon>
        </a>
      </div>
      <div class="absolute top-0 left-0 w-full z-50">
        <div class="flex">
          <div class="w-5/6 md:w-4/6">
            <sidebar
              v-show="show_menu"
              v-on:close-menu="closeMenu"
              :on_active="on_active"
              @create-new-card="$emit('create-card')"
              @close-side-bar="show_menu = !show_menu"
            ></sidebar>
          </div>
          <div
            class="bg_faint w-1/6 md:w-2/6 h-screen"
            v-if="show_menu"
            @click="show_menu = !show_menu"
          ></div>
        </div>
      </div>
    </div>

    <section class="w-full hidden xl:inline-block xl:col-span-3">
      <div class="shadow bg-white">
        <sidebar
          :on_active="on_active"
          @create-new-card="$emit('create-card')"
        ></sidebar>
      </div>
    </section>

    <section
      class="
        col-span-16
        xl:col-span-13
        h-screen
        overscroll-y-contain
        overflow-y-auto
        -mt-6
        xl:mt-0
      "
    >
      <div>
        <topbar></topbar>
      </div>
      <main class="mt-5">
        <slot></slot>
      </main>
    </section>
  </section>
</template>

<script>
import MenuIcon from '../Icons/menuIcon.vue'
import Sidebar from './Sidebar.vue'
import Topbar from './Topbar.vue'
export default {
  components: { Sidebar, Topbar, MenuIcon },
  data() {
    return {
      on_active: this.$attrs.on_active,
      show_menu: false
    }
  },
  methods: {
    closeMenu(val) {
      this.show_menu = val
    }
  }
}
</script>

<style></style>
